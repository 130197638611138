<template>
  <CModalExtended
    :title="modalTitle"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
  >
    <CRow class="options">
      <CCol
        :sm="getColNumber"
        v-for="bay in bayList"
        :key="bay.VesselBayId"
        class="bay-col-item"
      >
        <bay-container
          cargoView
          smallHeader
          disabledSelect
          :margin="-1"
          :bayInfo="bay"
          :baySlot="stowagePlanningCargoId"
          :twoBays="bayList.length > 1"
          :showNumbers="false"
          :colSize="generalCol"
          :positionsToDeck="getPositionsToDeck(bay)"
          :positionsUnderDeck="getPositionsUnderDeck(bay)"
        />
      </CCol>
    </CRow>
    <template #footer>
      <CButton
        shape="square"
        color="wipe"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x" />
        <span class="ml-1">{{ $t("button.cancel") }}</span>
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import BayContainer from "@/pages/planificacion-estiba/archivos/bays/bay-container";
import BayMixin from "@/_mixins/bays";

//Data
function data() {
  return {
    isLoading: false,
    modalActive: this.modal,
    bayList: [],
    generalCol: 0,
  };
}

//Methods
function toggle(newVal) {
  if(newVal && this.stowagePlanningCargoId)
    this.getBays();
  else{
    this.bayList = [];
    this.generalCol = 0;
  }

  this.modalActive = newVal;
}
function getBays() {
  this.$store.state.planificacionestiba.apiStateForm = 1;

  this.$http
    .ejecutar("GET", "StowagePlanningVesselBay-bycargoId", {
      StowagePlanningCargoId: this.stowagePlanningCargoId,
    })
    .then((response) => {
      this.bayList = response.data.data;
      this.generalCol = this.generalResponsiveCol(this.bayList);
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.$store.state.planificacionestiba.apiStateForm = 0;
    });
}

//Computed
function hasBayData() {
  return this.bayList.length != 0;
}
function modalTitle() {
  return `BAYSLOT: ${this.baySlot}`
}
function getColNumber() {
  return this.hasBayData ? (12/this.bayList.length) : 12;
}

export default {
  name: "bay-slot-modal",
  components: {
    BayContainer,
  },
  mixins: [BayMixin],
  props: {
    modal: Boolean,
    stowagePlanningCargoId: {
      type: String,
      default: '',
    },
    baySlot: {
      type: String,
      default: '',
    }
  },
  data,
  methods: {
    toggle,
    getBays,
  },
  computed: {
    modalTitle,
    hasBayData,
    getColNumber,
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
};
</script>

<style scoped>
.options {
  min-height: 210px;
  max-height: 540px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.bay-col-item {
  position: relative;
  width: 100%;
  height: 100%;
  /* flex: 1; */
  overflow: hidden;
  padding: 5px 0 10px;
}
</style>